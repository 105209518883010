.btn {
    padding: $ratio3 $ratio6;
    background-color: $color-cta;
    border-radius: 30px;
    transition: all 0.3s ease;
    color: $color-white;

    &:hover {
        background-color: #C9C9C9;
        color: $color-black;        
    }
}

.btn.btn-lg {
    padding: $ratio5 $ratio8;
}

.captcha {
    visibility: hidden;
}

.buttons {
    @extend .flex;
    gap: $ratio12;

    @media screen and (max-width: $size-xs-max) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: $ratio8;
    }
}

.btn-submit {
    button {
        padding: $ratio3 $ratio6;
        border: none;
        cursor: pointer;

        font-family: $font-family-2;
        font-size: 18px;
    }
}

.btn-filedoc { 
    label {
        padding: $ratio3 $ratio6;
        background-color: $color-cta;
        border-radius: 30px;
        font-family: $font-family-2;
        font-size: 18px;
        font-weight: 400;
        color: $color-white;
        line-height: 1.2;
        width: fit-content;
        height: 30px;
        min-height: calc(48px - #{$ratio3 * 2});
        max-height: calc(57px - #{$ratio3 * 2});
        @extend .flex;
        @extend .items-center;

        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: #C9C9C9;
            color: $color-black;
        }
    }
}
