.slideshow-wrap {
    margin-top: $size-nav-height;
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}
    .ls-icon-unmuted, .ls-icon-muted {
      display: none!important;
    }

    .facebook {
        height: 90%;
        width: calc(100% - 15%);
        padding: 0 7.5%;
        position: absolute;
        bottom: 0;
        z-index: $z-index-slide-caption;
        @extend .flex;
        @extend .justify-end;

        img {
            transition: all 0.3s ease;
        }

        a:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    .hero__content {
        width: calc(100% - 15%);
        padding: 0 7.5%;
        position: absolute;
        bottom: 20%;
        left: 0;
        z-index: $z-index-slide-caption;


        h1 {
            font-size: $font-size-60;
            font-weight: 500;
            color: $color-white;
        }

        p {
            width: 70%;
            padding-top: $ratio4;
            color: $color-white;
        }

        .btn-wrapper {
            padding-top: $ratio8;
        }
    }

    @media screen and (max-width: $size-md-max) {
        margin-top: $size-nav-height-mobile;

        .hero__content {
            h1 {
                font-size: $font-size-40;
            }
        }

    }
    
    @media screen and (max-width: $size-md-min) {
        .facebook {
            a {
                img {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .hero__content {
            bottom: 10%;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .facebook {
            a {
                img {
                    height: 24px;
                    width: 24px;
                }
            }
        }

        .hero__content {
            h1 {
                width: 80%;
                font-size: $font-size-30;
            }
            
            p {
                display: none;
            }

            .btn-wrapper {
                padding-top: $ratio4
            }
        }
    }

    .ls-bottom-nav-wrapper {
        padding: 0 7.5%;
        width: 100%;
        position: absolute;
        bottom: 15%;
        left: 0;
        z-index: $z-index-slide-caption;
        
        .ls-bottom-slidebuttons {
            @extend .flex;
            @extend .items-center;
            gap: $ratio2;

            a {
                background-color: $color-white;
                height: $ratio4;
                width: $ratio4;
                border-radius: 50%;
                border: solid 3px transparent !important;
                transition: all 0.6s ease !important;

                &.ls-nav-active {
                    height: $ratio4;
                    width: $ratio4;
                    border: solid 3px $color-tertiary !important;
                    background-color: $color-white;
                }

                img {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $size-md-min) {
            display: none;
        }
    }
}
