/*
    Where we set the typography of the template
 */

// sets default font for the website and links
body {
    @extend .text-color;
    @extend .font-1-normal;
    line-height: 1;
    a {
        color: $color-white;
        font-family: $font-family-2;
        text-decoration: none !important;
        display: inline-block;

        &:hover, &:focus {
            text-decoration: none !important;
             @extend .text-color-contrast;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-1;
    line-height: 1.3;
}

h2 {
    font-size: $font-size-40;
}

h3 {
    font-size: $font-size-30;
}

p {
    font-family: $font-family-2;
    font-size: $font-size-20;
    font-weight: 400;
    line-height: 1.4;
}

ul {
    padding-left: $ratio5;
}

li {
  line-height: 1.3;
  font-family: $font-family-2;
}
