$input_height: 90px;
$input_back_color: $color-grey-light;
$input_text_color: $color-black;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-primary;
$input_placeholder_size: 16px;

$input_label_color: $color-black;
$input_label_size: $font-size-18;

$input_border_color: #2B2B2B;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $color-black;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, 
    textarea.form-control {
        width: 100%;
        padding: $ratio6 $ratio4;
        height: $input_height;
        color: $input_text_color;
        font-family: $font-family-2;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border: none;
        border-radius: $input_border_radius;
        box-sizing: border-box;
    }
    input::placeholder, textarea::placeholder {
        font-family: $font-family-2;
        font-size: $input_placeholder_size;
        color: #C9C9C9;
    }
    input:focus, textarea:focus {
        border: solid 1px $color-primary;
        outline: none;
    }
    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

//// checkboxes and radiobuttons style ///////


    .checkbox , .radio {
        display: flex;
        cursor: pointer;
        width: 100%;
        & > span {
            color: #024b94;
            padding: 0.5rem 0.25rem;
            margin-right: auto;
        }
        & >  input {
          height: 25px;
          width: 25px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          outline: none;
          transition-duration: 0.3s;
          background-color: $input_back_color;
          cursor: pointer;
          position: inherit!important;
        }
        & > input:checked {
          border: 3px solid $input_back_color;
          background-color: #024b94;
        }
    }

    .radio > input {
      border-radius: 50%;
    }

//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
    }
}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    ul {
        list-style: none;
        padding: 0;
    }

    li {
        padding-top: $ratio1;
        color: $color-error;
        line-height: 1;
    }
}



.messages {
    //padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}

.section-contact-form,
.section-carriere-form {   
    h2 {
        padding-bottom: $ratio6;
        font-size: $font-size-24;
        color: $color-dark;
    }
    
    .input-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $ratio6;
        row-gap: $ratio2;

        .form-group {
            ::placeholder {
                font-family: $font-family-2;
            }
        }
    
        .message {
            grid-column: 1 / -1;

            ::placeholder {
                font-family: $font-family-2;
            }

            textarea {
                font-family: $font-family-2;
                resize: none;
            }
        }
    
    }
    
    .form-footer {
        padding: $ratio10 $ratio8; 
    }

    @media screen and (max-width: $size-md-min) {
        .input-section {
            grid-template-columns: 1fr;
            padding: 0;
        }

        .form-footer {
            padding: $ratio10 0;
        }
    }
}

.section-carriere-form {
    input.form-control, 
    textarea.form-control {
        background-color: $color-white;
        border: $input_border_style $input_border_size $input_border_color;
        border-radius: $input_border_radius;
    }

    h4 {
        padding-left: $ratio8;
        font-family: $font-family-2;
        font-size: 18px;
        font-weight: 700;
        color: $color-dark;
        text-transform: uppercase;
    }

    .input-section {
        padding: $ratio8;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $ratio6;
        row-gap: $ratio2;

        .form-group {
            ::placeholder {
                font-family: $font-family-2;
            }
        }

        .adresse {
            grid-column: 1 / -1;
        }
    
        .message {
            grid-column: 1 / -1;

            ::placeholder {
                font-family: $font-family-2;
            }

            textarea {
                font-family: $font-family-2;
                resize: none;
            }
        }
    
    }
    
    .form-footer {
        padding: $ratio10 $ratio8; 
    }

    @media screen and (max-width: $size-md-min) {
        h4 {
            padding-left: 0;
        }

        .input-section {
            grid-template-columns: 1fr;
            padding: $ratio8 0;
        }

        .form-footer {
            padding: $ratio6 0 $ratio10 0;
        }
    }
}
