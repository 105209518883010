.job {
    @extend .flex;
    @extend .flex-column;
    gap: $ratio2;

    .job__title {
        background-color: #004290;
        width: 100%;

        h4 {
            padding: $ratio6 $ratio10;
            font-family: $font-family-2;
            font-size: $font-size-20;
            font-weight: 500;
            text-transform: uppercase;
            color: $color-grey-light;
        }
    }
    
    .job__description {
        padding: $ratio6 $ratio10 0 $ratio10;
        background-color: #EAF3FF;

        .job__infos {
            @extend .flex;
            @extend .items-center;
            gap: $ratio6;

            .info {
                @extend .flex;
                @extend .items-center;
                gap: $ratio2;
                font-family: $font-family-2;
                font-weight: 600;
                color: #004290;
            }
        }

        .job__content {
            padding-top: $ratio6;

            h1, h2, h3, h4, h5 {
                padding-top: $ratio6;
                font-family: $font-family-2;
                font-size: 18px;
                font-weight: 700;
                color: $color-dark;
            }

            ul {
                margin-top: $ratio4;
                padding-left: $ratio6;
                list-style: disc;
                @extend .flex;
                @extend .flex-column;
                gap: $ratio2;
            }

            li {
                font-size: 18px;
                color: $color-dark;
                line-height: 1.3;
            }

            .btn-wrapper {
                padding: $ratio12 0;
            }
        }
    }
}