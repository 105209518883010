/*****************************************************/
// MENU
/* needs _sources/js/main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: fixed;
    top: 0;
    z-index: $z-index-menu;
    width: 100%;
    height: $size-nav-height;
    @extend .flex;
    @extend .justify-center;
    @extend .items-center;

    ul {
        list-style: none;
    }
    
    .wrapper {
        height: inherit;
        width: 100%;
        padding: 0 7.5%;
        background-color: $color-white;
        z-index: $z-index-menu + 1;
        @extend .flex;
        @extend .items-center;

        .right {
            @extend .flex;
            @extend .items-center;
            gap: $ratio16;

            .phone {
                @extend .flex;
                @extend .items-center;
                gap: $ratio2;
                color: $color-primary;

                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    &.nav-invisible {
        top: -$size-nav-height;
        transition: all $transition-speed-fast ease;
    }
    &.nav-visible {
        top: 0;
        transition: all $transition-speed-fast ease;
    }

    @media screen and (max-width: $size-nav-shift-breakpoint) {
        height: $size-nav-height-mobile;
        transition: all $transition-speed-fast ease;

        .img-responsive {
            height: 50px;
        }

        &.nav-invisible {
            top: -$size-nav-height-mobile;
            transition: all $transition-speed-fast ease;
        }
        &.nav-visible {
            top: 0;
            transition: all $transition-speed-fast ease;
        }
    }

    /************ menu desktop ***************************/
    .menu-navbar {
        @extend .flex;
        @extend .justify-between;
        @extend .items-center;

        width: 100%;
        height: $size-nav-height;
        position: inherit;
        transition: all $transition-speed-fast ease
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            .right {
                .phone {
                    display: none;
                }
            }
        }
    }
}

/************ menu toggle ***************************/
.menu-toggle {
    width: 70px;
    padding: $ratio3 $ratio6;
    background-color: $color-cta;
    border-radius: 30px;
    cursor: pointer;

    .word {
        font-family: $font-family-2;
        font-size: $font-size-20;
        color: $color-white;
        text-align: center;
        transition: all 0.3s ease;
        @extend .flex;
        @extend .justify-center;
    }

    .open {
        display: block;
    }
    .close {
        display: none;
    }

    &.toggled {
        .open {
            display: none;
        }
        .close {
            display: block;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        width: 45px;
    }
}

/************ menu mobile ***************************/
.menu-mobile {
    width: 60%;
    height: 80%;
    top: 0;
    right:0;
    z-index: -1;
    overflow: hidden;
    position: fixed;
    background-color: rgba($color: $color-tertiary, $alpha: 0.75);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    transform: translateY(-100%);
    transition: all $transition-speed-fast ease-in;

    &.toggled,
    &.toggled {
        transform: translate(0);
    }

    .menu-mobile-wrapper {
        height: inherit;
        margin-top: $ratio12;
        padding: $ratio12;
    }

    ul {
        padding: $ratio8 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @extend .justify-between;
        column-gap: $ratio4;
        row-gap: $ratio8;

        li {
            a {
                font-size: $font-size-30;
                font-weight: 500;
                color: $color-grey-light;
                white-space: nowrap;
                transition: all 0.3s ease;
            }

            &:hover {
                a {
                    color: $color-cta;
                }
            }
        }
    }

    @media screen and (max-width: $size-md-max) {
        width: 100%;
        height: 100%;

        .menu {
            grid-template-columns: 1fr;
        }
    }
}

