footer {
    padding: $ratio8 0;
    background-color: $color-black;

    .wrapper {
        @extend .flex;
        @extend .justify-between;
        @extend .items-center; 

        .copyright {
            padding: $ratio2 0 0 $ratio2;
            @extend .flex;
            gap: $ratio2;

            p {
                font-size: 14px;
                color: $color-grey-light;
            }
        }
        
        ul.menu {
            list-style: none;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $ratio2;

            a {
                font-size: 16px;
                color: $color-grey-light;
                transition: all 0.3s ease;
                border-bottom: solid 1px transparent;

                &:hover {
                    border-bottom: solid 1px $color-grey-light;
                }
            }
        }

        .mediaweb_social {
            @extend .flex;
            @extend .justify-between;
            gap: $ratio12;
        }
    }

    @media screen and (max-width: $size-md-max) {
        .wrapper {
            gap: $ratio8;
            
            ul.menu {
                display: none;
            }

            .mediaweb_social {
                width: fit-content;
                gap: $ratio4;

                .facebook {
                    img {
                        height: 32px;
                        width: 32px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            .mediaweb_social {
                width: 100%;
            }
        }
    }
}