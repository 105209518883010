// IMPORT ADDONS
//@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
@import 'utilities/wrapper';


@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
@import 'sections/section-emplois';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
//@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

.wrapper.grid-2col {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: $size-xs-max) {
        grid-template-columns: 1fr;
    }
}

.section_hero.image {
    padding-top: $size-nav-height;
    background-color: $color-white;
    
    img.hero__media {
        width: 100%;
        object-fit: cover;
        display: block;
    }
    img.hero__media.mobile {
        height: 400px;
        display: none;
    }

    @media screen and (max-width: $size-md-max) {
        padding-top: $size-nav-height-mobile;
    }
    
    @media screen and (max-width: $size-xs-max) {

        img.hero__media {
            display: none;
        }
        img.hero__media.mobile {
            display: block;
        }
    }
}

img.section__media {
    display: block;
    object-fit: cover;
}
img.section__media.mobile {
    display: none;
}

.spacer {
    margin-top: $ratio16;
    border: none;
    border-bottom: solid 1px $color-grey;
}

.spacer.primary {
    border-color: $color-primary;
}

.spacer.light {
    border-color: $color-secondary;
}


/*****************************************************/
/* ACCUEIL
/*****************************************************/

.section-01-accueil {
    padding: $ratio16 0;
    background-color: $color-white;

    .wrapper {
        width: 100%;

        .left {
            padding: $ratio12 7.5%;
            background-color: $color-primary;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            h3 {
                padding-bottom: $ratio4;
                color: $color-secondary;
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: $color-grey-light;
            }
        }

        .right {
            img {
                height: 100%;
            }
        }
    }
}

.section-02-accueil {
    padding-bottom: $ratio16;
    background-color: $color-white;

    .wrapper {
        h2 {
            color: $color-black;
            padding-bottom: $ratio6;
        }

        .spacer {
            margin-top: 0;
        }

        .cards {
            padding-top: $ratio8;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            gap: $ratio4;

            .card {
                .card__media {
                    width: 100%;
                    object-fit: cover;
                }
                .card__content {
                    min-height: 260px;
                    padding: $ratio4;
                    background-color: $color-grey-light;

                    p {
                        font-size: 16px;
                        color: #231F20;
                    }
                }
            }

        }
    }
    @media screen and (max-width: $size-md-mid) {
        .wrapper {
            .cards {
                grid-template-columns: 1fr 1fr;
        
                .card {
                    .card__content {
                        min-height: 140px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            h2 {
                font-size: $font-size-30;
            }

            .cards {
                grid-template-columns: 1fr;
            }
        }
    }
}

.section-03-accueil {
    margin-bottom: $ratio8;
    padding: $ratio12 0;
    background-color: $color-secondary;

    .wrapper {
        h1, 
        h2,
        h4 {
            font-family: $font-family-3;
            font-weight: 400;
            text-align: center;
        } 
        
        h2 {
            width: 70%;
            font-size: $font-size-40;
            color: $color-primary;
            text-align: left;
        }

        .cards {
            padding: $ratio8 0;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 1fr;
            gap: $ratio4;

            .card {
                padding: $ratio4 $ratio2;
                height: auto;
                width: auto;
                background-color: $color-primary;

                .card__content {
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-between;
                    @extend .items-center;
                    gap: $ratio8;

                    h1 {
                        font-size: $font-size-80;
                        color: $color-white;
                        line-height: 1;
                    }
                    h4 {
                        min-height: 50px;
                        font-size: $font-size-24;
                        color: $color-white;
                        line-height: 1.2;
                        @extend .flex;
                        @extend .items-start;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-md-max) {
        .wrapper {
            h2 {
                width: 100%;              
            }
            .cards {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            .cards {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}


/*****************************************************/
/* SERVICES
/*****************************************************/

.section-01-services {
    padding: $ratio12 0;
    background-color: $color-white;

    .wrapper {
        width: 100%;
        gap: $ratio3;

        .left {
            img {
                height: 100%;
            }
        }

        .right {
            padding: $ratio10 7.5%;
            background-color: $color-grey-light;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            h3 {
                color: $color-black;
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: $color-dark;
            }

            ul {
                margin-top: $ratio6;
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: $ratio8;
                row-gap: $ratio4;
            }

            li {
                font-size: 16px;
                font-weight: 400;
                color: $color-dark;
            }
        }

        @media screen and (max-width: $size-md-mid) {
            grid-template-columns: 1fr;

            .left {
                order: 2;

                img {
                    height: 400px;
                    width: 100%;
                }
            }
            .right {
                order: 1;
            }
        }

        @media screen and (max-width: $size-xs-max) {
            .right {
                ul {grid-template-columns: 1fr;}
            }
        }
    }
}

.section-02-services {
    padding-bottom: $ratio12;
    background-color: $color-white;

    .wrapper {
        width: 100%;
        @extend .flex;
        gap: $ratio3;

        .left {
            width: 30%;
            padding: $ratio10 7.5%;
            background-color: $color-grey-light;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;
        }

        .right {
            width: 70%;

            img {
                height: 100%;
            }
        }

        @media screen and (max-width: $size-md-mid) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;

            .left {
                width: auto;
            }
            .right {
                width: auto;

                img {
                    height: 400px;
                    width: 100%;
                }
            }
        }
    }
}

.section-03-services {
    padding: $ratio8 0;
    background-color: $color-secondary;

    .wrapper {
        gap: $ratio10;

        .right {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            .title {
                padding-top: $ratio6;
                font-weight: 700;
            }
        }
    }
}

.section-04-services {
    padding: $ratio12 0;

    .wrapper {
        gap: $ratio12;

        .left {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            .title {
                padding-top: $ratio6;
                font-weight: 700;
            }

            li {
                padding-top: $ratio4;
                font-size: 16px;
                font-weight: 400;
                color: $color-dark;
            }
        }

        .right {
            @extend .flex;
            @extend .items-center;

            img {
                height: 100%;
                width: 100%;
            }
        }

        @media screen and (max-width: $size-md-max) {
            width: 100%;

            .left {
                padding: 0 7.5%;
            }

            .right {
                img {
                    height: 80%;
                }
            }
        }

        @media screen and (max-width: $size-xs-max) {
            .right {
                img {
                    height: 400px;
                }
            }
        }
    }
}

.section-05-services {
    padding: $ratio10 0;
    background-color: $color-grey-light;

    .wrapper {
        gap: $ratio10;

        .right {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio4;

            .title {
                padding-top: $ratio6;
                font-weight: 700;
            }

            li {
                padding-top: $ratio4;
                font-size: 16px;
                font-weight: 400;
                color: $color-dark;               
            }

            .btn-wrapper {
                margin-top: $ratio4;
            }
        }

        .left {
            @extend .flex;
            @extend .items-center;
        }
    }
}

.section-06-services {
    padding: $ratio12 0;
    background-color: $color-white;

    .wrapper {
        width: 100%;
        gap: $ratio3;
       
        .left {
            padding: $ratio10 7.5%;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;
            
            h3 {
                color: $color-black;
            }
            
            p {
                font-size: 18px;
                font-weight: 400;
                color: $color-dark;
            }
            
            
            .title {
                padding-top: $ratio6;
                font-weight: 700;
            }
            
            li {
                padding-top: $ratio4;
                font-size: 16px;
                font-weight: 400;
                color: $color-dark;
            }
        }
        
        .right {
            img {
                height: 100%;
            }
        }
        @media screen and (max-width: $size-md-mid) {
            grid-template-columns: 1fr;

            .left {
                order: 1;
            }
            .right {
                order: 2;

                img {
                    height: 400px;
                    width: 100%;
                }
            }
        }
    }
}

.section-07-services {
    padding-bottom: $ratio16;
    background-color: $color-white;

    .wrapper {
        display: grid;
        grid-template-columns: 4fr 6fr;
        gap: $ratio4;

        .card {
            padding: $ratio12 $ratio10;
            background-color: $color-secondary;
            border-radius: 30px;

            .card__content {
                @extend .flex;
                @extend .flex-column;
                gap: $ratio6;

                h2 {
                    color: $color-black;
                }

                p {
                    a {
                        color: $color-dark;
                        text-decoration: underline !important;
                    }
                }

                .btn-wrapper {
                    margin-top: $ratio4;
                }
            }
        }

        @media screen and (max-width: $size-md-mid) {
            grid-template-columns: 1fr;

            .card {
                padding: $ratio8 $ratio6;
            }
        }
    }
}


/*****************************************************/
/* EQUIPEMENTS NEUFS
/*****************************************************/


.section-01-equipements {
    padding: $ratio12 0;
    background-color: $color-white;

    .wrapper {
        width: 100%;
        gap: $ratio3;
        @extend .flex;

        .left {
            width: 70%;
            background-color: $color-secondary;

            img {
                height: 100%;
            }
        }

        .right {
            width: 30%;
            padding: $ratio10 7.5% $ratio10 $ratio8;
            background-color: $color-grey-light;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-start;
            gap: $ratio6;

            h3 {
                color: $color-black;
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: $color-dark;
            }
        }

        @media screen and (max-width: $size-md-max) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;

            .left {
                width: auto;
                order: 2;

                img {
                    width: 100%;
                }
            }
            .right {
                width: auto;
                order: 1;
            }
        }
    }
}

.section-02-equipements {
    padding: $ratio10 0;
    background-color: $color-secondary;

    .wrapper {
        grid-template-columns: 3fr 7fr;

        .left {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-start;
            gap: $ratio6;

            .btn-wrapper {
                margin-top: $ratio4;
            }
        }

        .right {
            @extend .flex;
            @extend .items-center;
        }

        @media screen and (max-width: $size-md-max) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: $size-xs-max) {
            grid-template-columns: 1fr;
            row-gap: $ratio6;
        }
    }
}

.section-03-equipements {
    padding: $ratio12 0;

    .wrapper {
        width: 100%;
        gap: $ratio3;

        .left {
            height: 100%;
            background-color: $color-secondary;
            @extend .flex;
            @extend .items-center;
        }
        
        .right {
            padding: $ratio8 7.5%;
            background-color: $color-grey-light;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            .btn-wrapper {
                margin-top: $ratio4;
            }
        }

        @media screen and (max-width: $size-xs-max) {
            grid-template-columns: 1fr;
            row-gap: $ratio6;

            .left {
                order: 2;
            }
            .right {
                order: 1;
            }
        }
    }
}

.section-04-equipements {
    padding: $ratio12 0;

    .wrapper {
        width: 100%;
        gap: $ratio3;
        
        .left {
            padding: $ratio8 7.5%;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;
        }

        .right {
            height: 100%;
            background-color: $color-secondary;
            @extend .flex;
            @extend .items-center;
        }

        @media screen and (max-width: $size-xs-max) {
            grid-template-columns: 1fr;
            row-gap: $ratio6;
        }
    }
}

.section-05-equipements {
    padding: $ratio12 0;
    
    .wrapper {
        
        .spacer.light {
            margin-top: $ratio6;
        }
        
        .cards {
            padding: $ratio10 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $ratio4;

            .card {
                padding: $ratio10 $ratio6;
                background-color: $color-secondary;
                border-radius: 30px;

                .card__content {
                    @extend .flex;
                    @extend .flex-column;
                    gap: $ratio6;

                    h2 {
                        color: $color-black;
                    }
                }
            }
        }

        @media screen and (max-width: $size-md-max) {
            .cards {
                grid-template-columns: 1fr;
            }
        }
    }
}


/*****************************************************/
/* COORDONNEES
/*****************************************************/

.section-01-coordonnees {
    padding: $ratio12 0;

    .wrapper {      
        .content {
            display: grid;
            grid-template-columns: 4fr 5fr;
            gap: $ratio16;

            .left {
                padding-top: $ratio4
            }
            .right {
                @extend .flex;
                @extend .justify-end;
                @extend .items-center;

                .infos {
                    @extend .flex;
                    @extend .flex-wrap;
                    @extend .items-start;
                    gap: $ratio16;

                    .info {
                        h5 {
                            padding-bottom: $ratio6;
                            font-family: $font-family-2;
                            font-size: $font-size-20;
                            font-weight: 500;
                            color: $color-primary;
                        }

                        a {
                            color: #777475;
                            line-height: 1.6;
                        }
                    }
                }

            }
        }

        .spacer.primary {
            margin-top: $ratio12;
        }

        @media screen and (max-width: $size-md-max) {         
            .content {
                grid-template-columns: 1fr;

                .right {
                    -webkit-justify-content: flex-start;
                    -moz-justify-content: flex-start;
                    -ms-justify-content: flex-start;
                    justify-content: flex-start;
                }
            }
        }
    }
}

.section-contact-form {
    .wrapper {
        display: grid;
        grid-template-columns: 4fr 6fr;
        gap: $ratio16;

        .schedule__box {
            @extend .flex;
            @extend .flex-column;
            gap: $ratio6;

            h4 {
                padding-bottom: $ratio8;
                font-family: $font-family-2;
                font-size: $font-size-20;
                font-weight: 500;
                color: $color-black;
            }
            
            .day_time {   
                width: 100%;            
                @extend .flex;
                @extend .justify-between;
                @extend .items-center;

                p {
                    font-size: 16px;
                }

                .day {
                   font-weight: 500;
                   color: $color-black; 
                }
            }
        }

        @media screen and (max-width: $size-md-max) {
            grid-template-columns: 1fr;

            .schedule__box {
                .day_time {
                    width: 50%;
                }
            }
        }

        @media screen and (max-width: $size-xs-max) {
            .schedule__box {
                .day_time {
                    width: 100%;
                }
            }
        }
    }
}


/*****************************************************/
/* CARRIERE
/*****************************************************/

.section-01-carriere {
    padding: $ratio12 0;

    .wrapper {      
        display: grid;
        grid-template-columns: 6fr 3fr;
        gap: $ratio16;

        .left {
            padding-top: $ratio4;

            p {
                padding-top: $ratio6;
                font-family: $font-family-1;
                font-size: $font-size-20;
                font-weight: 500;
            }
        }
        .right {
            .title {
                padding-bottom: $ratio4;
                font-size: 18px;
                font-weight: 700;
            }

            li {
                padding-top: $ratio3;
                font-size: 18px;
            }
        }

        @media screen and (max-width: $size-md-max) {         
            grid-template-columns: 1fr;
        }
    }
}

.section-emplois {
    margin-bottom: $ratio12;

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $ratio8;
    }   
    
    h2 {
            width: 100%;
            font-family: $font-family-2;
            font-size: $font-size-30;
            font-weight: 700;
            color: $color-dark;
            text-align: center;
        }
        
        .spacer {
            margin: $ratio4 0 $ratio10 0;
            border-color: #AEB0B3;
        }
    }

    @media screen and (max-width: $size-md-mid) {
    .wrapper {
        .content {
            grid-template-columns: 1fr;
            row-gap: $ratio8;
        }  
    }
}

.section-carriere-form {
    margin-bottom: $ratio12;
    padding-top: $ratio4;
    background-color: $color-secondary;
}

/*****************************************************/
/* INVENTAIRES
/*****************************************************/

section.section-search {
    padding: 78px 0 30px 0;
    .wrapper {
        @extend .flex;
        @extend .items-center;
    }
    #search-bar {
        width: 100%;
        @extend .flex;
    }

    #search-input {
        width: 100%;
        background-color: #C9C9C972;
        background-image: url('../images/icon_search.png');
        background-position: center left 20px;
        background-repeat: no-repeat;
        height: 48px;
        font-size: 18px;
        border: none;
        padding-left: 60px;
        &:focus, &:active,&:focus-visible {
            border: none;
            border: #fff;
        }
    }


    @media screen and(max-width: $size-slider-breakpoint) {
        padding: 40px 0;
        .wrapper {
            flex-wrap: wrap;
        }
        #search-bar {
            margin-left: 0;
            order: -1;
            margin-bottom: 30px;
        }
    }
}

section.section-gallery {

    #filter-toggle-button {
        cursor: pointer;
        z-index: 2;
        
        img{
            opacity: .6;  
            transition: .3s;      
        }
        &:hover, &.actif {
            img {
                opacity: 2;
                transition: .3s;
            }
        }
    }
    .btn-filter {
        font-size: 18px;
        color: #231F20;
        background-color: #EDEDED;
        width: 140px;
        margin-left: 40px;
        border-radius: 25px;
        text-align: center;
        padding: 15px;
        transition: .3s;
        &:hover {
            background-color: #d7d5d5;
            transition: .3s;
        }
    }

    div.gallery-body {
        overflow: hidden;
        position: relative;
        //min-height: 130vh;

        #filters {
            position: absolute;
            transform: translateX(-101%);
            left: 0;
            top: 0;
            z-index: 1;
            width: 250px;
            //height: 100%;
            overflow-y: auto;
            //background-color: #EDEDED;
           
            
            transition: 0.3s;
            z-index: 999;
            div {
                padding: 20px;
                background-color: #EDEDED;    
                //margin-bottom: 5px; 

            }
            &.open {
                transform: translateX(0);
                transition: .3s;
            }
            h3 {
                margin-top: 0;
            }

            .filter-category {
                border-bottom: solid 1px #000;
                h4 {
                    font-size: 16px;
                    margin: 0;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:after {
                        content: ' ';
                        background-image: url('../images/icon_arrow_down.png');
                        background-size: contain;
                        background-position: center;
                        width: 25px;
                        height: 25px;
                        transition: transform 0.3s;
                    }
                    &.collapsed:after {
                        transform: rotate(-90deg);
                    }
                }
            }
            .filter-options {
                display: none;
                padding: 0;
            }
            
            label {
                display: block;
                margin: 15px 0;
                font-size: 16px;
            }
            input[type="radio"] {
                margin-right: 10px;
            }
        }

        .gallery-overlay {
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            transition: .3s;
            position: fixed;
            visibility: hidden;
            pointer-events: auto;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 1;
            &.open {
                opacity: 1;
                visibility: visible;
                transition: .3s;
                cursor: url('../images/close.png'), auto;
            }
        }
    }
    #product-gallery {
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 70px 16px;

        @media screen and (max-width: 1200px){
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(2, 1fr);
                 @media screen and (max-width: $size-slider-breakpoint){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }

        .product {
            opacity: 1;
            transition: 0.2s;
            //transform: scale(1);
            
            & img {
                max-width: 100%;
                height: auto;
            }

            &.hidden {
                opacity: 0;
                transition: 0.2s;
                //transform: scale(0.5);
            }
            &.invisible {
                display: none;
            }

            div.photo-wrapper {
                border-radius: 10px;
                overflow: hidden;
                img {
                    transition: .3s;
                }
                &:hover {
                    img {
                        transform: scale(1.05);
                        transition: .3s;
                    }
                }
            }
            h5 {
                font-size: 20px;
                font-weight: 700;
                margin: 47px 0 15px 0;
            }
            h4 {
               font-size: 18px;
               font-weight: 600; 
            }
            p, li {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}



/*fiche de produits*/
section.section-fiche-product {
    padding: 50px 0;

    div.arrow_back {
        max-width: 120px;
        flex: none;
        margin-right: 50px;
        .btn-back {
            width: 100px;;
            background-color: #EDEDED;
            color: $color-black;
            padding: 10px 16px;
            border-radius: 35px;
            font-size: 18px;
            transition: .3s;;
            &:hover {
                font-weight: 600;
                transition: .3s;
            }
        }
    }
    div.left-side {
        width: 80%;
        .owl-wrapper {
            width: 50%;
            float: left;
            margin-right: $ratio16;
            margin-bottom: $ratio8;
            
        }
        h3 {
            font-size: 18px;
            font-weight: 700;;
        }
        p {
            font-size: 16px;
        }
        .btn {
            max-width: 200px;
            margin-top: 30px;
        }
        @media screen and (max-width: 1200px){
            width: 100%;
            .owl-wrapper {
                margin-right: $ratio8;
                margin-bottom: $ratio8;
            }
        }
        @media screen and (max-width: 600px){
            & > div , .owl-wrapper {
                width: 100%;
            }
        }
    }
}